<template>
  <div>
    <div class="q-ma-md">
      <q-list>
        <q-item>
          <q-item-section>
            <q-item-label>历史对局记录[最近10场]</q-item-label>
          </q-item-section>

          <q-item-section side top>
            <q-btn-group outline>
              <q-btn color="primary" label="刷新" @click="init"/>
            </q-btn-group>
          </q-item-section>
        </q-item>

        <q-scroll-area style="height: 630px;">
          <q-item @click="detail(game)" class="q-mb-sm" v-for="game in gameHistory" :key="game.game_id" clickable
                  v-ripple>

<!--            <q-item-section>-->
<!--              <q-item-label caption lines="1" style="font-size: 18px">-->
<!--                <q-avatar rounded size="75px" style="margin-right: 10px">-->
<!--                  <img :src="game.map_image">-->
<!--                </q-avatar>-->
<!--              </q-item-label>-->
<!--            </q-item-section>-->

            <q-item-section>
              <q-item-label caption lines="1" >
                游戏ID:{{ game.game_id }}
              </q-item-label>
            </q-item-section>

            <q-item-section>
              <q-item-label caption lines="1">
                <q-badge v-if=" game.is_win == 1" color="primary">
                  获胜
                </q-badge>
                <q-badge v-else color="red">
                  战败
                </q-badge>
              </q-item-label>
            </q-item-section>

            <q-item-section>
              <q-item-label caption lines="1">
                地图:{{ game.map_name }}
              </q-item-label>
            </q-item-section>

            <q-item-section>
              <q-item-label caption lines="1">
                队伍:{{ game.team }}
              </q-item-label>
            </q-item-section>

            <q-item-section>
              <q-item-label caption lines="1">
                队伍A:{{ game.survivor_score }} / 队伍B:{{ game.infected_score }}
              </q-item-label>
            </q-item-section>


            <q-item-section>
              <q-item-label caption lines="1">
                Rank分变化:{{ game.before_rank }} - {{ game.after_rank }}
              </q-item-label>
            </q-item-section>

          </q-item>
        </q-scroll-area>
      </q-list>
    </div>
  </div>
</template>

<script>
import {historyGame} from "@/api/player";

export default {
  name: "MyGameHistory",
  data() {
    return {
      gameHistory: []
    }
  },
  created() {
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      historyGame().then((res) => {
        this.gameHistory = res.data.extend;
      })
    },
    detail(game) {
      this.$router.push({
        path: '/detail/' + game.game_id,
      })
    }
  }
}
</script>

<style scoped>

</style>
