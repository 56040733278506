import request from '@/router/axios'

export function bindAccount(query) {
    return request({
        url: '/api/user/bindAccount',
        method: 'get',
        params: query
    })
}

export function uploadProfile(query) {
    return request({
        url: '/api/user/uploadProfile',
        method: 'get',
        params: query
    })
}

export function ranks(query) {
    return request({
        url: '/api/user/rank',
        method: 'get',
        params: query
    })
}

export function initRank(query) {
    return request({
        url: '/api/user/initRankScore',
        method: 'get',
        params: query
    })
}

export function rankConfirm(query) {
    return request({
        url: '/api/user/rankConfirm',
        method: 'get',
        params: query
    })
}

export function otherMap(query) {
    return request({
        url: '/api/user/other_map',
        method: 'get',
        params: query
    })
}

export function historyGame(query) {
    return request({
        url: '/api/user/my_games',
        method: 'get',
        params: query
    })
}

export function allGameHistories(gameId, pageNo, pageSize) {
    return request({
        url: '/api/user/all_games',
        method: 'get',
        params: {"gameId": gameId, "pageNo": pageNo, "pageSize": pageSize}
    })
}

export function detailGame(query) {
    return request({
        url: '/api/user/game_detail',
        method: 'get',
        params: query
    })
}
